import React from 'react';
import '../assets/styles/pages/quote/booking-confirmed.scss';
import ExclamationMark from '../images/icons-exclamation-mark.inline.svg';

import Layout from '../components/layout';
import SEO from '../components/seo';

const QuoteIndexPage = () => {
  return (
    <Layout wrapperClass="quote-selection-container" hideHeaderFooter={true}>
      <SEO title="Quote Selection" />
      <div className="booking-confirmation-wrapper">
        <div className="container xs booking-confirmation-intro">
          <div className="label">
            Reference Number
            <span>#9ORRGOR</span>
          </div>
          <img src="https://via.placeholder.com/150x" className="booking-confirmation-image" />
          <h1>Booking Confirmed</h1>
          <p className="intro">
            Camper Collision Centre will confirm your requested drop-off date within 24 hours or contact you to reschedule.
          </p>
        </div>
        <div className="booking-info">
          <div className="container xs">
            <ExclamationMark />
            <p>
              We are doing one final check with the Panel Beater to ensure they are ready to take your car on your selected date. We will send you a final confirmation email shortly.
            </p>
            <p>
              Please be ready to pay the Panel Beater directly at the completion of your repairs. The price is only for the quoted services, andy additional work may be negotiated between you and Panel Beater.
              Should the Panel Beater discover additional damages upon physical inspection that was not visible in the images they will always contact you first.
              Thank you for supporting DingGo a brand new way to get your car repaired!
            </p>
            <p>
              <strong>
                Need help? <a href="mailto:test@dinggo.com.au">Contact Us</a>
              </strong>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default QuoteIndexPage;
